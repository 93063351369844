<template>
  <div class="goodsTabs">
    <span
      :class="{ active: curTab === item.value }"
      v-for="(item, i) in tabs"
      :key="i"
      @click="$emit('update:curTab', item.value)"
      >{{ item.label }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    tabs: Array,
    curTab: Number,
  },
};
</script>

<style lang="scss" scoped>
.goodsTabs {
  display: flex;
  align-items: flex-end;
  margin: 20px 0 15px;
  span {
    color: #72777e;
    font-size: 18px;
    margin-right: 30px;
    cursor: pointer;
    &.active {
      color: #3377ff;
      font-size: 22px;
      position: relative;
      &::after {
        content: " ";
        width: 20px;
        height: 3px;
        background-color: #3377ff;
        border-radius: 2px;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
