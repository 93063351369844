<template>
  <div class="courseCard">
    <div>
      <img
        class="courseCard-img"
        :src="course.playType === 0 ? live : replay"
        alt=""
      />
      <div class="courseCard-text">
        <span>{{ course.sectionName }}</span>
        <img
          v-if="course.playType === 0 && [2, 3].includes(course.lessonStatus)"
          :src="living"
          alt=""
        />
        <!-- <div class="courseCard-text-time">
          <span>{{ course.courseBeginTime }}-{{ course.courseEndTime }}</span>
          <span class="minute">{{ course.courseHours }}分钟</span>
        </div> -->
      </div>
    </div>
    <common-button v-if="canTryView(course)" :handle-click="()=>tryViewVideo(course)">
      <div class="try_btn">试看</div>
    </common-button>
    <common-button
      v-if="course.playType === 0 && course.lessonStatus < 4"
      :handle-click="lookLive"
      :type="disable"
      >观看直播</common-button>
  </div>
</template>

<script>
import CommonButton from '@/components/CommonButton.vue';

export default {
  components: { CommonButton },
  props: {
    course: Object,
  },
  data() {
    return {
      live: require('@/assets/img/course/live_icon@2x.png'),
      replay: require('@/assets/img/course/replay_icon@2x.png'),
      living: require('@/assets/img/course_live.png'),
    };
  },
  computed: {
    disable() {
      let val = '';
      if (this.course.lessonStatus === 1) {
        val = 'disable';
      } else if ([2, 3].includes(this.course.lessonStatus)) {
        val = 'primary';
      }
      return val;
    },
  },
  methods: {
    canTryView(course) {
      const { playType, playbackType, allowTrial } = course || {};
      return playType * 1 === 1 && playbackType * 1 === 0 && allowTrial * 1 === 1;
    },
    lookLive() {
      const { lessonStatus, courseId, courseDetailId } = this.course;
      if ([2, 3].includes(lessonStatus)) { // 观看直播
        if (!courseId) { this.$message.error('欢拓courseId不能为null'); return false; }
        window.open(`/livePlay/${courseId}/${courseDetailId}`);
      } else {
        return false;
      }
      return true;
    },
    tryViewVideo(course) {
      const { playbackContent, sectionName } = course || {};
      window.open(
        `/videoReplay?videoUrl=${encodeURIComponent(playbackContent)}&name=${encodeURIComponent(sectionName)}`,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.courseCard {
  padding: 15px 20px;
  background: #f8f8f8;
  border-radius: 9px;
  width: 920px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  & > div {
    display: flex;
    align-items: center;
  }
  &-img {
    width: 34px;
    height: 34px;
    margin-right: 26px;
  }
  &-text {
    color: #131b33;
    font-size: 20px;
    display: flex;
    align-items: center;
    img {
      width: 65px;
      height: 24px;
      margin-left: 10px;
    }
    &-time {
      .minute {
        margin-left: 15px;
      }
    }
  }
  .try_btn{
    width:100%;
    text-align: center;
  }
}
</style>
