<template>
  <div class="details" v-html="detail"></div>
  <empty-text v-if="!detail">暂无课程详情</empty-text>
</template>

<script>
import EmptyText from './EmptyText.vue';

export default {
  components: { EmptyText },
  props: {
    detail: String,
  },
};
</script>

<style lang="scss" scoped>
.details {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
}
</style>
