<template>
  <div class="goodsInfo" id="goodsInfo">
    <div class="backBar">
      <div class="backBox"><back-btn :handleClick="() => $router.push('home')"/></div>
    </div>
    <div class="goodsInfo-bar">
      <info-card />
    </div>
  </div>
</template>

<script>
import BackBtn from '@/components/BackBtn.vue';
import InfoCard from './components/InfoCard.vue';

export default {
  components: {
    BackBtn,
    InfoCard,
  },
  computed: {
    isLogin() {
      return !!localStorage.getItem('token');
    },
  },
  mounted() {
    document.getElementById('goodsInfo').style.scrollTop = 0;
    const { itemCode, salePersonId } = this.$route.query;
    if (this.isLogin && salePersonId) {
      this.$store.dispatch('goods/bindSeller', {
        codes: [itemCode],
        sid: salePersonId,
      });
    }
    if (!this.isLogin && salePersonId) {
      localStorage.setItem('bindSeller', JSON.stringify({
        codes: [itemCode],
        sid: salePersonId,
      }));
    }
  },
};
</script>

<style lang="scss" scoped>
.goodsInfo{
  .backBar{
    height: 80px;
    background-color: #F4F4F4;
    display: flex;
    align-items: center;
    .backBox{
      width: 1200px;
      margin: 0 auto;
    }
  }
  &-bar{
    width: 1200px;
    margin: 20px auto;
  }
}
</style>
