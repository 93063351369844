<template>
  <div class="commentList">
    <comment-card v-for="(item, i) in commentList" :key="i" :comment="item"
                  @update="$emit('commentUpdate',{comment:$event,index:i})"/>
    <empty-text v-if="!commentList.length">暂无考鸭评价</empty-text>
  </div>
</template>

<script>
import CommentCard from './CommentCard.vue';
import EmptyText from './EmptyText.vue';

export default {
  components: { CommentCard, EmptyText },
  props: {
    commentList: Array,
  },
};
</script>

<style lang="scss" scoped>
.commentList{
  background-color: #fff;
  padding: 25px 15px;
  border-radius: 6px;
}
</style>
