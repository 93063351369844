<template>
  <div class="infoCard">

    <video :src="goodsInfo.auditionVideoUrl"
           controls="controls" :poster="skuInfo.picture || goodsInfo.imageUrl"
           controlsList="nodownload" class="infoCard-img" v-if="goodsInfo.auditionVideoUrl">
      您的浏览器不支持 video 标签。
    </video>
    <img class="infoCard-img" :src="skuInfo.picture || goodsInfo.imageUrl" alt="" v-else/>
    <div class="infoCard-text">
      <div class="infoCard-text-name">{{ goodsInfo.itemName }}</div>
      <div class="infoCard-text-point">{{ skuInfo.sellingPoint }}</div>
      <div class="infoCard-text-price">
        <span>￥ {{ Number(skuInfo.price).toFixed(2) }}</span>
        <!-- <span v-if="skuInfo.showOriginalPrice" class="originalPrice"
          >￥ {{ skuInfo.originalPrice }}</span
        > -->
      </div>
      <div v-if="[2, 3].includes(goodsType)" class="infoCard-text-teacher">
        <span
          >上课时间：{{ courseInfo.chargeCourseBeginDate }}至{{
            courseInfo.chargeCourseEndDate
          }}</span
        >
        <span>授课老师：{{ courseInfo.teacherName }}</span>
      </div>
      <div class="infoCard-text-sku">
        <div
          class="skuItem"
          :class="{ active: activeI === i }"
          v-for="(item, i) in itemList"
          :key="i"
          @click="changeSku(i)"
        >
          <span>{{ item.itemName }}</span>
          <div v-if="item.skuDesc" class="skuItem-tag" v-text="formatText(item.skuDesc)"></div>
        </div>
      </div>
      <div v-if="[2, 3, 21].includes(goodsType)" class="infoCard-text-btn">
        <span v-if="isHasCourse ? (aftWxNum && aftUrl) : (preWxNum && preUrl)" @click="chatVisible = true">立即咨询</span>
        <span
          v-if="isHasCourse"
          @click="$router.push(`/courseDetail/${goodsInfo.courseId}`)"
          class="buy"
          >查看课程</span
        >
        <span v-else @click="buyIt" class="buy">立即购买</span>
      </div>
      <div v-else class="infoCard-text-btn">
        <span v-if="wxUrl && wxNum" @click="chatVisible = true">立即咨询</span>
        <span
          v-if="isHasCourse"
          @click="$router.push(`/courseDetail/${goodsInfo.courseId}`)"
          class="buy"
          >查看课程</span
        >
        <span v-else @click="buyIt" class="buy">立即购买</span>
      </div>
    </div>
  </div>
  <goods-tabs v-model:cur-tab="curTab" :tabs="tabs" />
  <goods-details v-if="isCurTab('detail')" :detail="courseInfo.sourseDesc || skuInfo.itemDesc" />
  <course-list
    v-if="isCurTab('courseTable')"
    :active-course-list="activeCourseList"
    :course-nav="courseNav"
    v-model:cur-nav="curNav"
    @handleNav="changeTimeTable"
  />
  <teachers-list v-if="isCurTab('teachers')" :list="goodsInfo.teacherMiniVOList||[]"></teachers-list>
  <div v-infinite-scroll="loadMoreComment" v-if="isCurTab('comment')">
    <comment-list
      :comment-list="commentList"
      @commentUpdate="handleCommentUpdate"
      @loadMore="loadMoreComment"
    />
  </div>

  <buy-course-modal
    v-model:visible="buyCourseVisible"
    :course-info="courseInfo"
    :sku-info="itemList[activeI]"
    @confirm="toPayOrder"
  />
  <!-- 小助手弹框 -->
  <el-dialog title="购买咨询" v-model="chatVisible" center top="35vh" width="320px">
    <div class="wechat">
      <div class="tip">
        微信扫码下方二维码添加{{ goodsType === 2 || goodsType === 3 || goodsType === 21 ? "班主任" : "小助手" }}咨询
      </div>
      <img :src="[2, 3, 21].includes(goodsType) ? isHasCourse ? aftUrl: preUrl : wxUrl" alt="" referrerPolicy="no-referrer" />
      <div>微信号：{{ [2, 3, 21].includes(goodsType) ? isHasCourse ? aftWxNum : preWxNum : wxNum }}</div>
    </div>
  </el-dialog>
  <!-- 购买须知 -->
  <el-dialog title="购买须知" center v-model="buyVisible" width="420px">
    <div class="buyText" v-if="goodsType === 5 || goodsType === 15">
      <p>
        1.口语课程可使用有效期为120天，逾期未使用视为作废，请同学们在有效时间内多加练习哦～
      </p>
      <p class="second">
        2.对于已经预约好的课程需要做时间调整或是课程取消，是需要提前至少3小时和助教老师沟通的哦～
      </p>
      <p>
        3.未添加小助手的考鸭们记得添加小助手，她会有针对性的给你备考建议的～
      </p>
    </div>
    <div class="buyText" v-if="goodsType === 4 || goodsType === 14">
      <p>
        1.批改订单可使用有效期为120天，超期未使用作废，请同学们抓紧时间勤加练习哟～
      </p>
      <p class="second">
        2.为了保证批改质量，所有普通订单均在2天内返稿，故需要同学们耐心等待～
      </p>
      <p>
        3.未添加小助手的考鸭们记得添加小助手，她会有针对性的给你备考建议的～
      </p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="buyVisible = false">取 消</el-button>
        <el-button type="primary" @click="toPayOrder">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import http from "@/utils/request";
import { connectState } from "@/utils/wyUtil";
import GoodsTabs from "./GoodsTabs.vue";
import GoodsDetails from "./GoodsDetails.vue";
import CourseList from "./CourseList.vue";
import CommentList from "./CommentList.vue";
import BuyCourseModal from "./BuyCourseModal.vue";
import TeachersList from "./TeachersList.vue"

export default {
  components: {
    GoodsTabs,
    GoodsDetails,
    CourseList,
    CommentList,
    TeachersList,
    BuyCourseModal
  },
  data() {
    return {
      buyCourseVisible: false,
      activeI: 0,
      curTab: 0,
      activeCourseList: [],
      courseNav: [],
      curNav: null,
      chatVisible: false,
      buyVisible: false,
      commentList: [],
      pageInfo: {
        curPage: 1,
        limit: 10
      },
      commentTotal: 0,
      itemCode: "",
    };
  },
  computed: {
    ...connectState("goods", [
      "goodsInfo",
      "tabs",
      "goodsType",
      "itemList",
      "skuInfo",
      "courseInfo",
      "courseList",
      "preWxNum",
      "aftWxNum",
      "preUrl",
      "aftUrl",
      "wxUrl",
      "wxNum",
      "isHasCourse",
    ]),
    curTabName() {
      return (this.tabs.find(item => item.value === this.curTab && item) || {}).name || "detail";
    }
  },
  watch: {
    skuInfo(val) {
      if (this.goodsType === 2 || this.goodsType === 3) {
        if (localStorage.getItem("token"))
          this.$store.dispatch("goods/isBuyCourse", { id: val.attrValueList });
        this.$store.dispatch("goods/getCourseInfo", {
          chargeCourseId: val.attrValueList
        });
        this.$store.dispatch("goods/getCourseList", {
          chargeCourseId: val.attrValueList
        });
      }
    },
    courseList(val) {
      this.courseNav = val;
      if (val && val.length) {
        this.curNav = val[0].key;
        this.activeCourseList = Array.isArray(val) ? (val[0] || {}).list : [];
      }
    },
    goodsInfo(val) {
      // const data = {
      //   curPage: 1,
      //   limit: 9999,
      //   catalog: val.itemCatalog,
      // };
      // this.$store.dispatch('goods/getItemCommentsPage', { ...data });
      const { itemCatalog } = this.goodsInfo;
      const param = {
        catalog: itemCatalog,
        ...this.pageInfo,
        itemCode: this.itemCode
      };
      this.getCommentList(param);
    }
  },
  created() {
    localStorage.removeItem("goods");
    this.itemCode = this.$route.query.itemCode;
    this.$store.dispatch("goods/getGoodsInfo", {
      itemCode: this.$route.query.itemCode
    });
  },
  methods: {
    isCurTab(name) {
      return this.curTabName === name;
    },
    async getCommentList(param) {
      console.log("getCommentList");
      const rs = await http("/hcp/user/comment/getItemCommentsPage", {
        method: "POST",
        data: param
      });
      this.commentTotal = rs.total;
      if (param.curPage === 1) {
        this.commentList = rs.list;
      } else {
        this.commentList = [...this.commentList, ...rs.list];
      }
    },
    loadMoreComment() {
      if (!this.isCurTab('comment')) {
        // 需要选中评论tab
        return;
      }
      if (this.commentTotal && this.commentList.length >= this.commentTotal) {
        // 数据已经请求好了
        return;
      }
      // eslint-disable-next-line operator-assignment
      this.pageInfo.curPage = this.pageInfo.curPage + 1;
      const { itemCatalog } = this.goodsInfo;
      this.getCommentList({ catalog: itemCatalog, ...this.pageInfo, itemCode: this.itemCode });
    },
    handleCommentUpdate(e) {
      this.commentList[e.index] = e.comment;
    },
    formatText(val) {
      return val.length > 4 ? (val = `${val.substr(0, 4)}...`) : val;
    },
    // 切换规格
    changeSku(i) {
      this.activeI = i;
      this.$store.commit("goods/CHANGE_SKU", i);
    },
    // 切换章节
    changeTimeTable(key) {
      const arr = this.courseList || [];
      this.activeCourseList = (arr.find(item => item.key === key) || {}).list || [];
    },
    // 立即购买
    async buyIt() {
      const params = { itemId: this.skuInfo.itemId };
      const res = await http("/hcp/trade/tradeOrder/hasItemCount", { params });
      if (res !== true) return this.$message.error("超出购买次数限制");
      const rs = await http("/hcp/trade/tradeOrder/upay", { params });
      if (rs) {
        return this.$confirm("当前商品有未支付的订单，前往我的订单查看？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning"
        }).then(() => {
          this.$router.push("userOrders?tab=1");
        });
      }
      if ([4, 5, 14, 15].includes(this.goodsType)) {
        this.buyVisible = true;
      } else if ([2, 3].includes(this.goodsType)) {
        this.buyCourseVisible = true;
      } else {
        this.toPayOrder();
      }
    },
    // 去订单支付
    toPayOrder() {
      this.buyVisible = false;
      const { itemCode } = this.goodsInfo;
      const salePersonId = this.$route.query.salePersonId || "";
      const userCouponId = this.$route.query.userCouponId || "";
      let path = `submitOrder?itemCode=${itemCode}&skuIndex=${this.activeI}`;
      if (salePersonId) path += `&salePersonId=${salePersonId}`;
      if (userCouponId) path += `&userCouponId=${userCouponId}`;
      this.$router.push(path);
    }
  }
};
</script>

<style>
.el-dialog__title {
  font-size: 20px;
  color: #131b33;
  font-weight: bold;
}

.el-dialog--center .el-dialog__body {
  padding-top: 10px;
}
</style>

<style lang="scss" scoped>
.wechat {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #72777e;
  img {
    width: 146px;
    margin: 10px 0;
  }
}

.buyText {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #4e596f;
  font-weight: 600;
  p.second {
    margin: 15px 0;
  }
}

.infoCard {
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  &-img {
    width: 470px;
    height: 352.5px;
    margin-right: 20px;
    object-fit: scaleToFill;
    flex-shrink: 0;
  }
  &-text {
    &-name {
      color: #131b33;
      font-weight: 600;
    }
    &-point {
      font-size: 14px;
      margin: 10px 0;
    }
    &-price {
      font-size: 22px;
      color: #ff2342;
      font-weight: bold;
      margin-bottom: 10px;
      .originalPrice {
        color: #72777e;
        text-decoration: line-through;
        font-size: 16px;
        margin-left: 20px;
        font-weight: normal;
      }
    }
    &-teacher {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      span {
        margin-bottom: 10px;
      }
    }
    &-sku {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0 10px;
      .skuItem {
        min-width: 80px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
        background: #f8f8f8;
        color: #a0a2ab;
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 16px;
        position: relative;
        border-radius: 3px;
        &.active {
          background-color: rgba(51, 119, 255, 0.16);
          color: #3377ff;
        }
        &-tag {
          position: absolute;
          right: 0;
          top: -15px;
          padding: 0 5px;
          border-radius: 0 8px 0 8px;
          height: 20px;
          line-height: 20px;
          background-color: #f36;
          color: #fff;
          font-size: 12px;
        }
      }
    }
    &-btn {
      height: 40px;
      line-height: 40px;
      width: 200px;
      border-radius: 5px;
      border: 1px solid #3377ff;
      display: flex;
      span {
        flex: 1;
        text-align: center;
        color: #3377ff;
        cursor: pointer;
        &.buy {
          color: #fff;
          background-color: #3377ff;
        }
      }
    }
  }
}
</style>
