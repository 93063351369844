<template>
  <el-dialog title="课程确认" center v-model="dialogVisible" width="500px">
    <div class="bug_course_confirm_modal_content">
      <p class="row_item">
        当前已选规格：
        <span class="high_light_text">【{{ skuInfo.itemName }}】</span>。
        <span class="high_light_text">{{ courseInfo.teacherName }}</span>
        ,在
        <span class="high_light_text">
          {{ courseInfo.chargeCourseBeginDate }}
          -
          {{ courseInfo.chargeCourseEndDate }}
        </span>
        日开设的课程。
      </p>
      <p class="row_item confirm_tip">确认要继续购买该课程吗？</p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">重新选择</el-button>
        <el-button type="primary" @click="handleConfirm">立即下单</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: 'buyCourseModal',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    skuInfo: {
      type: Object,
      default: () => ({}),
    },
    courseInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    immediate: true,
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    handleConfirm() {
      this.cancel();
      this.$emit('confirm');
    },
  },
};
</script>
<style lang='scss' scoped>
.bug_course_confirm_modal_content {
  line-height: 28px;
  padding: 30px 0px;
  color: #4e596f;
  font-size: 18px;
  .high_light_text {
    color: #3377ff;
  }
  .row_item {
    display: block;
    margin-bottom:10px;
  }
  .confirm_tip {
    text-align: center;
  }
}
</style>
