<template>
  <div class="teachersTabList">
    <div v-for="(info, index) in list" :key="info.teacherId" class="teacherListItem">
      <div class="teacherWrapper">
        <img :src="info.picUrl" alt="avatar" class="teacherImg" />
      </div>
      <div class="textWrapper">
        <div class="text">{{ info.teacherName }}</div>
        <div class="textRows">
          <span class="rowWrapper">{{
            atciveIndexs.includes(index) ? info.teacherDesc : wrapStr(info.teacherDesc)
          }}</span>
          <span>{{
            atciveIndexs.includes(index) ? "" : showBtn(info.teacherDesc) ? "... " : ""
          }}</span>
          <span
            class="rightBtn"
            :class="atciveIndexs.includes(index) ? 'block' : null"
            v-if="showBtn(info.teacherDesc)"
            @click="handleClick(index)"
            >{{ atciveIndexs.includes(index) ? "收起" : "更多" }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "teachersList",
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      atciveIndexs: []
    };
  },
  methods: {
    wrapStr(str) {
      return str ? str.substring(0, 79) : str;
    },
    showBtn(str) {
      let str1 = str ? str.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, "") : str;
      return str && str1.length > 79;
    },
    handleClick(index) {
      if (this.atciveIndexs.includes(index)) {
        this.atciveIndexs = this.atciveIndexs.filter(item => item !== index && item);
      } else {
        this.atciveIndexs = [...this.atciveIndexs, index];
      }
    }
  }
};
</script>

<style lang="scss">
.teachersTabList {
  width: 100%;
  padding: 35px 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 6px;
  .teacherListItem {
    width: 525px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
    .teacherWrapper {
      width: 120px;
      height: 120px;
      border-radius: 60px;
       background: #eaebec;
      margin-right: 24px;
      overflow: hidden;
    }
    .teacherImg {
      display: block;
      width: 120px;
      height: 120px;
          }
    .textWrapper {
      flex: 1;
      padding-top: 10px;
    }
    .text {
      width: 100%;
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #131b33;
      line-height: 22px;
      margin-bottom: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .textRows {
      font-size: 14px;
      font-weight: 400;
      color: #363d51;
      line-height: 20px;
    }
    .rightBtn {
      line-height: 20px;
      color: #3377ff;
      font-size: 14px;
      margin-left: 5px;
      cursor: pointer;
      float: right;
      &.block {
        margin-top: 20px;
      }
    }
  }
}
</style>
