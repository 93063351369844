<template>
  <div class="courseNav">
    <div
      class="courseNav-item"
      :class="{ active: activeNav === item.key }"
      v-for="(item, i) in courseNav"
      :key="i"
      @click="handleNav(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courseNav: Array,
    curNav: String,
  },
  data() {
    return {
      activeNav: this.curNav,
    };
  },
  watch: {
    curNav(val) {
      this.activeNav = val;
    },
  },
  methods: {
    handleNav(item) {
      this.activeNav = item.key;
      this.$emit('update:curNav', item.key);
      this.$emit('handleNav', item.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.courseNav {
  display: flex;
  &-item {
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    background-color: #fff;
    color: #a0a2ab;
    padding: 0 24px;
    box-shadow: 0px 2px 10px 0px rgba(120, 154, 172, 0.18);
    cursor: pointer;
    margin-right: 15px;
    &.active {
      background: linear-gradient(52deg, #ff3366 0%, #ffaa1e 100%);
      color: #fff;
    }
  }
}
</style>
