<template>
  <div class="courseList">
    <course-nav
      :cur-nav="curNav"
      :course-nav="courseNav"
      @handleNav="handleNav"
    />
    <div v-if="activeCourseList.length" class="courseList-line"></div>
    <course-card v-for="(item, i) in activeCourseList" :key="i" :course="item" />
    <empty-text v-if="!activeCourseList.length">暂无课程安排</empty-text>
  </div>
</template>

<script>
import CourseCard from './CourseCard.vue';
import CourseNav from './CourseNav.vue';
import EmptyText from './EmptyText.vue';

export default {
  components: { CourseNav, CourseCard, EmptyText },
  props: {
    courseNav: Array,
    activeCourseList: Array,
    curNav: String,
  },
  methods: {
    handleNav(val) {
      this.$emit('handleNav', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.courseList {
  background-color: #fff;
  padding: 15px;
  &-line {
    width: 1200px;
    height: 3px;
    background: #f8f8f8;
    margin: 15px 0 0;
    margin-left: -15px;
  }
}
</style>
