<template>
  <div class="empty">
    <img class="empty-img" :src="empty" alt="">
    <span class="empty-text"><slot></slot></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      empty: require('../../../assets/img/empty.png'),
    };
  },
};
</script>

<style lang="scss" scoped>
.empty{
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-img{
    width: 150px;
    height: 120px;
    margin-bottom: 10px;
  }
  &-text{
    color: #CACDD6;
  }
}
</style>
