<template>
  <div class="star-picker">
    <div v-for="(item,index) in stars" :key="index" class="wrap">
      <img class="icon-star" :src="require('@/assets/img/icon-star-red.png')" v-if="item.type==='red'"
             @click="setScore(index+1)" />
      <img class="icon-star" :src="require('@/assets/img/icon-star-blank.png')" v-if="item.type==='blank'"
             @click="setScore(index+1)"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StarPicker',
  props: {
    value: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stars() {
      const redStars = [];
      const blankStars = [];
      for (let r = 0; r < parseInt(this.value); r++) {
        redStars.push({
          type: 'red',
        });
      }
      for (let r = 0; r < parseInt(5 - parseInt(this.value)); r++) {
        blankStars.push({
          type: 'blank',
        });
      }
      return [...redStars, ...blankStars];
    },
  },
  data() {
    return {};
  },
  methods: {
    setScore(score) {
      if (!this.disabled) {
        this.$emit('input', score);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .star-picker {
    display: flex;
    align-items: center;
    .wrap{
      display: flex;
      align-items: center;
    }
    .icon-star {
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }
  }
</style>
