<template>
  <div class="commentCard">
    <img class="commentCard-img" :src="comment.fromUserHead" alt="">
    <div class="commentCard-text">

      <div class="commentCard-text-top">
        <div class="left">
          <span class="name">{{comment.fromUserName}}</span>
          <star-picker :value="comment.score"></star-picker>
        </div>

      </div>
      <div class="commentCard-text-content">{{comment.content}}</div>

      <div class="commentCard-text-bottom">
        <div class="date">
          {{publishTimeRule(comment.createDate)}}
        </div>
        <div class="like-wrap">
          <img class="icon-finger" :src="require('@/assets/img/icon-finger-blue.png')"
               v-if="comment.userIsLike" @click="handleUserNotPraiseGeneral"/>
          <img class="icon-finger" :src="require('@/assets/img/icon-finger-blank.png')"
               v-else @click="handleUserPraiseGeneral"/>
          <span>{{comment.like}}人觉得有用</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarPicker from '@/components/StarPicker.vue';
import http from '@/utils/request';
import { publishTimeRule } from '@/utils/format';

export default {
  components: { StarPicker },
  props: {
    comment: Object,
  },
  methods: {
    publishTimeRule,
    getSubjectType(commentType) {
      if (commentType === 18) {
        return 21;
      } if (commentType === 22) {
        return 25;
      }
      return 25;
    },
    handleUserNotPraiseGeneral() {
      const data = {
        subjectId: this.comment.userCommentId,
        subjectType: this.getSubjectType(this.comment.commentType),
      };
      http('/hcp/user/praiseRecord/userNotPraiseGeneral', { method: 'POST', data }).then((res) => {
        this.$emit('update', { ...this.comment, userIsLike: false, like: this.comment.like - 1 });
      });
    },
    handleUserPraiseGeneral() {
      const data = {
        subjectId: this.comment.userCommentId,
        subjectType: this.getSubjectType(this.comment.commentType),
      };
      http('/hcp/user/praiseRecord/userPraiseGeneral', { method: 'POST', data }).then((res) => {
        this.$emit('update', { ...this.comment, userIsLike: true, like: this.comment.like + 1 });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.commentCard{
  border-bottom: 1px solid #CACDD6;
  padding-bottom: 25px;
  display: flex;
  margin-bottom: 25px;
  &-img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 20px;
  }
  &-text{
    flex: 1;
    font-size: 14px;
    color: #333;
    &-top{
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      .left{
        display: flex;
        align-items: center;
      }
      .name{
        font-weight: bold;
        margin-right: 20px;
      }
      .time{
        color: #A0A2AB;
        font-size: 12px;
        margin-left: 10px;
      }
      .score{
        color: #37f;
        font-size: 18px;
        margin-right: 5px;
      }
      .unit{
        color: #A0A2AB;
        font-size: 12px;
      }
    }
    &-content{
      color: #72777E;
    }
    &-bottom{
      margin-top: 15px;
      display: flex;
      .date{
        font-size: 14px;
        font-weight: 400;
        color: #A0A2AB;
        line-height: 20px;
        flex: 1;
      }
      .like-wrap{
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: #A0A2AB;
        line-height: 20px;
        margin-right: 10px;
        .icon-finger{
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
